// react & gatsby modules
import * as React from "react"
import {graphql} from "gatsby";


// components
import Seo from "../layout/seo";
import Layout from "../layout/layout";
import {getParagraph} from "../paragraps/paragraph_Handler";

const SimplePageHome = ({ data, location }) => {
  const paragraphs = data.nodePage.relationships.field_content.map(getParagraph);

  return <Layout>{paragraphs}</Layout>
}

export const data = graphql`
    query {
        nodePage(path: {alias: {eq: "/homepage"}}) {
          field_meta_tags {
            title
            description
          }
          field_header_text
          id
          title
          path {
            alias
          }
          relationships {
            field_header_image {
              localFile {
                childImageSharp {
                  gatsbyImageData
                }
              }
            }
            field_content {
              type: __typename
                ... ParagraphBioBlock
                ... ParagraphBoxedText
                ... ParagraphBulletedList
                ... ParagraphCollaboratorsBlock
                ... ParagraphHeader
                ... ParagraphHomepageCategoriesList
                ... ParagraphHomepageHeader
                ... ParagraphImage
                ... ParagraphInfographicBlock
                ... ParagraphLinkedImage
                ... ParagraphSubheader
                ... ParagraphTextBlock
                ... ParagraphTextImageBlock
                ... ParagraphVideoBlockWide
                ... ParagraphWellnessVideoBlock
                ... ParagraphCollaboratorsFullList
                ... ParagraphContributorsList
                ... ParagraphWellnessHCPBlock
                ... ParagraphWellnessVideosBlock
                ... ParagraphHomepageEventsList
                ... ParagraphEventsBlock
                ... ParagraphResourcesList
            }
            
            field_ld_json {
              id
              field__type
              field_pair_1_name
              field_pair_1_value_text_
              field_pair_2_name
              field_pair_2_value_text_
              relationships {
                field_pair_1_ {
                  localFile {
                    publicURL
                  }
                }
                field_pair_2_image {
                  localFile {
                    publicURL
                  }
                }
              }
            }
          }
        }
    }
    `

export const Head = ({ data }) => <Seo
  title={data.nodePage.title}
  metaTags={data.nodePage.field_meta_tags}
  ldjson={data.nodePage.relationships.field_ld_json}
/>

export default SimplePageHome
